<template>
    <b-card no-body class="p-1" ref="userAccounts">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <div class="demo-spacing-0 m-1 d-flex justify-content-between align-items-center"
                 dir="rtl"
            >
                <h3 v-if="$route.name === 'show-accounts'">
                    لیست زیر مجموعه ها
                </h3>
                <template v-else>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                        <feather-icon icon="FilterIcon"/>
                        فیلتر
                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" @click="downloadExcel">
                        <feather-icon icon="FilterIcon"/>
                        خروجی اکسل
                    </b-button>
                </template>
            </div>

            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <b-modal
                    ref="msg-modal"
                    cancel-variant="outline-secondary"
                    ok-title="ارسال پیام"
                    cancel-title="لغو"
                    centered
                    title="پیام جدید"
                    @ok.prevent="sendMessage"
                    v-model="messageModal"
                    size="lg"
            >
                <b-overlay :show="loading">
                    <b-form>
                        <b-form-group label="عنوان پیام">
                            <b-form-input
                                    type="text"
                                    placeholder="عنوان"
                                    v-model="message.title"
                            />
                        </b-form-group>
                        <b-form-group label="متن پیام">
                            <b-form-textarea placeholder="متن پیام" v-model="message.content"/>
                        </b-form-group>
                    </b-form>
                </b-overlay>
            </b-modal>

            <b-modal
                    ref="deposit-modal"
                    cancel-variant="outline-secondary"
                    ok-title="واریز"
                    cancel-title="لغو"
                    centered
                    :title="' واریز '+$coins[depositData.coin].persianName"
                    @ok.prevent="depositToUser"
                    v-model="depositModal"
                    size="sm"
            >
                <b-overlay :show="loading">
                    <b-form>
                        <b-form-group label="به کاربر">
                            <b-form-input
                                    type="text"
                                    :value="depositData.email"
                                    plaintext
                            />
                        </b-form-group>
                        <b-form-group label="مقدار واریزی">
                            <b-form-input
                                    type="text"
                                    v-model="depositData.depositAmount"
                                    @input="depositData.depositAmount = $toLocal(depositData.depositAmount)"
                            />
                        </b-form-group>
                        <b-form-group label="شناسه واریز">
                            <b-form-input
                                    type="text"
                                    v-model="depositData.transactionId"
                            />
                        </b-form-group>
                        <b-form-group label="متن دلخواه">
                            <b-form-textarea v-model="depositData.message"/>
                        </b-form-group>
                    </b-form>
                </b-overlay>
            </b-modal>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        style="white-space: nowrap; min-height : 320px"
                        @sort-changed="sort($event)"
                        no-local-sort

                >
                    <template #cell(email)="data">
                        <span dir="ltr" :class="{'text-success' : data.item.supervisor}"
                              v-tooltip="data.item.supervisor? 'کاربر بازارگردان': 'کاربر عادی'">
                            {{data.item.email}}
                        </span>
                    </template>

                    <template #cell(createdAtDateTime)="data">
                        <span dir="ltr">
                            {{$G2J(data.item.createdAtDateTime)}}
                        </span>
                    </template>

                    <template #cell(totalTradesAmount)="data">
                        <span dir="ltr">
<!--                            {{data.item.totalTradesAmount.toLocaleString()}}-->
                            {{data.item.totalTradesAmount? $toLocal(data.item.totalTradesAmount) : ''}}
                        </span>
                    </template>

                    <template #cell(authenticationType)="data">
                        <b-badge
                                pill
                                :variant="'light-'+authVariant(data.item.authenticationType)"
                                class="text-capitalize"
                        >
                            {{authLabel(data.item.authenticationType)}}
                        </b-badge>
                    </template>

                    <template #cell(validBankAccounts)="data">
                        <b-badge
                                pill
                                variant="light-info"
                                class="text-capitalize"
                        >
                            {{data.item.validBankAccounts}}
                        </b-badge>
                    </template>

                    <template #cell(isActive)="data">
                        <b-badge
                                pill
                                :variant="'light-'+statusVariant(data.item.isActive)"
                                class="text-capitalize"
                                :badge="12"
                        >
                            {{statusLabel(data.item.isActive)}}
                        </b-badge>
                    </template>

                    <template #cell(orders)="data">
                        <template
                                v-if="$havePermission('LIST_WITHDRAW') || $havePermission('LIST_HISTORIES')"
                        >
                            <feather-icon
                                    v-if="$havePermission('LIST_WITHDRAW')"
                                    icon="ShareIcon"
                                    size="20"
                                    class="text-danger cursor-pointer"
                                    :badge="data.item.orders"
                                    badge-classes="badge-danger cursor-pointer"
                                    v-tooltip="'درخواست های برداشت'"
                                    @click="activeOrdersPage(data.item.email)"
                            />
                            <feather-icon
                                    v-if="$havePermission('LIST_HISTORIES')"
                                    icon="ClockIcon"
                                    size="20"
                                    class="text-warning ml-2 cursor-pointer"
                                    v-tooltip="'تاریخچه'"
                                    @click="orderHistoryPage(data.item.email)"
                            />
                        </template>
                        <not-allowed v-else/>
                    </template>

                    <template #cell(action)="{item,index}">
                        <template
                                v-if="$havePermission('DETAILS_USERS') ||
                                 $havePermission('UPDATE_USERS') ||
                                 $havePermission('LIST_ORDERS') ||
                                 $havePermission('LIST_TRADES') ||
                                 $havePermission('LIST_MESSAGES')"
                        >
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>

                                <b-dropdown-item v-if="$havePermission('DETAILS_USERS')"
                                                 :to="{ name: 'show-accounts', params: { id: item.id } }">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">جزییات</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('UPDATE_USERS')" @click="changeStatus(index)">
                                    <feather-icon icon="RefreshCwIcon"/>
                                    <span class="align-middle ml-50">تغییر وضعیت</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('CREATE_NOTIFICATIONS')"
                                                 @click="openMessageModal(item.id)">
                                    <feather-icon icon="MessageSquareIcon"
                                                  badge-classes="badge-info test-badge"/>
                                    <span class="align-middle ml-50">ارسال پیام</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('UPDATE_WALLET')"
                                                 @click="openDepositModal(item)">
                                    <feather-icon icon="DollarSignIcon"
                                                  badge-classes="badge-info test-badge"/>
                                    <span class="align-middle ml-50">واریز مستقیم</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('LIST_ORDERS')"
                                                 :to="{ name: 'Orders', query: { customer: item.email } }">
                                    <feather-icon icon="ShoppingBagIcon"/>
                                    <span class="align-middle ml-50">سفارشات</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('LIST_TRADES')"
                                                 :to="{ name: 'Deals', query: { customer: item.email } }">
                                    <feather-icon icon="BriefcaseIcon"/>
                                    <span class="align-middle ml-50">معاملات</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('UPDATE_USERS')"
                                                 @click="changeSupervisor(index,item.supervisor)">
                                    <feather-icon icon="RefreshCwIcon"/>
                                    <span v-if="item.supervisor" class="align-middle ml-50">تغییر به کاربر عادی</span>
                                    <span v-else class="align-middle ml-50">تغییر به بازارگردان</span>
                                </b-dropdown-item>

                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                    <label for="perpage">تعداد در صفحه</label>
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BPagination,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BBadge,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BOverlay
    } from 'bootstrap-vue'
    import SearchAndFilter from "@/layouts/components/SearchAndFilter";
    import vSelect from 'vue-select';
    import NotAllowed from "@/layouts/components/NotAllowed";

    export default {
        name: 'Accounts',
        components: {
            NotAllowed,
            SearchAndFilter,
            BPagination,
            BCard,
            BButton,
            BTable,
            BDropdown,
            BDropdownItem,
            BBadge,
            vSelect,
            BForm,
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BOverlay
        },
        data: () => ({
            test: 0,
            pageLength: 3,
            dir: false,
            searchTerm: '',
            currentPage: 1,
            perPage: 10,
            rows: 200,
            userData: [],
            isActive: false,
            messageModal: false,
            depositModal: false,
            perPageOptions: [5, 10, 20, 50, 100],
            items: [],
            loading: false,
            loading2: false,
            depositData: {
                coin:'TOMAN',
                email: '',
                customerId: '',
                depositAmount: '',
                transactionId: '',
                message: ''
            },
            message: {
                id: '',
                title: '',
                content: ''
            },
            columns: [
                {
                    label: 'نام',
                    key: 'firstName',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'نام خانوادگی',
                    key: 'lastName',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'ایمیل',
                    key: 'email',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'تلفن همراه',
                    key: 'mobileNumber',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'تاریخ ساخت اکانت',
                    key: 'createdAtDateTime',
                    sortable: true,
                    searchType: 'date'
                },
                // {
                //     label: 'موجودی کیف پول',
                //     key: 'cash',
                //     sortable: true,
                //     searchType: 'number'
                // },
                {
                    label: 'مجموع معاملات انجام شده',
                    key: 'totalTradesAmount',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'احراز هویت',
                    key: 'authenticationType',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'تکمیل نشده', value: 'NOT_COMPLETE'},
                        {label: 'در انتظار تایید', value: 'PENDING'},
                        {label: 'تایید شده', value: 'AUTHORIZED'},
                        {label: 'تایید نشده', value: 'UNAUTHORIZED'},
                    ]
                },
                {
                    label: 'حساب های تایید شده',
                    key: 'validBankAccounts',
                    // sortable: true,
                    // searchType: 'number'
                },
                {
                    label: 'وضعیت',
                    key: 'isActive',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'غیر فعال', value: false},
                        {label: 'فعال', value: true}
                    ]
                },
                {
                    label: 'درخواست ها',
                    key: 'orders'
                },
                {
                    label: 'مدیریت',
                    key: 'action'
                },
            ],
        }),
        computed: {
            authVariant() {
                const a = {
                    NOT_COMPLETE: 'secondary',
                    UNAUTHORIZED: 'danger',
                    PENDING: 'warning',
                    AUTHORIZED: 'success',
                    FULL_AUTHORIZED: 'success',
                    CONFIRMED_IDENTITY: 'info',
                    UNIDENTIFIED: 'danger',
                    INFORMATION_CHANGED_PENDING: 'warning',
                    INFORMATION_CHANGED: 'secondary',
                }
                return e => a[e];
            },
            authLabel() {
                const a = {
                    NOT_COMPLETE: 'تکمیل نشده',
                    UNAUTHORIZED: 'تایید نشده',
                    PENDING: 'در انتظار تایید',
                    AUTHORIZED: 'تایید شده',
                    FULL_AUTHORIZED: 'تایید شده کامل',
                    CONFIRMED_IDENTITY: 'هوبت تایید شده',
                    UNIDENTIFIED: 'هوبت تایید نشده',
                    INFORMATION_CHANGED_PENDING: 'در انتظار تایید',
                    INFORMATION_CHANGED: 'تکمیل نشده',
                }
                return e => a[e];
            },
            statusVariant() {
                return e => e ? 'success' : 'danger';
            },
            statusLabel() {
                return e => e ? 'فعال' : 'غیر فعال';
            },
            // georgianToJallali(){
            //     return e => this.$jmoment(e, 'YYYY-MM-DDTHH:mm:ss').format('dddd jD jMMMM jYYYY')
            // }
        },
        methods: {
            activeOrdersPage(id) {
                this.push('withdraw-orders', id)
            },
            orderHistoryPage(id) {
                this.push('withdraw-history', id)
            },
            push(to, id) {
                this.$router.push({name: to, query: {customer: id}})
            },
            openDepositModal(e) {
                this.depositData.customerId = e.id
                this.depositData.email = e.email
                this.depositModal = true
            },
            async depositToUser() {
                if (!this.loading) {
                    this.loading = true
                    try {
                        let data = {...this.depositData}
                        data.depositAmount = this.$S2N(data.depositAmount)
                        await this.$axios.post('/wallets/manual-deposit', data)
                        this.loading = false
                        this.depositModal = false
                        let text = `مقدار ${this.depositData.depositAmount} ${this.$coins[this.depositData.coin].code} به کاربر ${this.depositData.email} واریز شد.`
                        await this.$error('', text , 'success')
                        this.depositData = {
                            coin:'TOMAN',
                            email: '',
                            customerId: '',
                            depositAmount: '',
                            transactionId: '',
                            message: ''
                        }
                    } catch (e) {
                        this.loading = false
                    }

                }
            },
            openMessageModal(e) {
                this.message.id = e
                this.messageModal = true
            },
            async sendMessage() {
                if (!this.loading) {
                    this.loading = true
                    try {
                        await this.$axios.post('/users/notifications/' + this.message.id, this.message)
                        this.loading = false
                        this.message = {
                            id: '',
                            title: '',
                            content: '',
                        }
                        this.messageModal = false
                        this.$error('پیام ارسال شد', '', 'success')
                    } catch (e) {
                        this.loading = false
                    }

                }
            },

            changeStatus(e) {
                this.$swal({
                    title: 'آیا از تغییر وضعیت کاربر مطمئن هستید؟',
                    // text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'بله',
                    cancelButtonText: 'خیر',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(async (result) => {
                    if (result.value) {
                        console.warn('change active')
                        this.state.loading = true
                        const address = '/users/' + (this.items[e].isActive ? 'de' : '') + 'activate/' + this.items[e].id
                        const res = await this.$axios.post(address)

                        !res.data.message.includes('activated')
                        await this.getData(this.currentPage, this.perPage)

                        this.$swal({
                            icon: 'success',
                            title: this.items[e].isActive ? 'کاربر فعال شد' : 'کاربر غیر فعال شد',
                            confirmButtonText: 'تایید',
                            // text: 'Your file has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    }
                })
            },

            changeSupervisor(e, status) {
                const title1 = status ? 'آیا از تغییر کاربر به حالت عادی مطمئن هستید؟' : 'آیا از تغییر وضعیت کاربر به بازار گردان مطمعن هستید؟'
                this.$swal({
                    title: title1,
                    // text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'بله',
                    cancelButtonText: 'خیر',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then(async (result) => {
                    if (result.value) {
                        this.state.loading = true
                        const address = '/users/make-market-supervisor/' + this.items[e].id

                        await this.$axios.post(address)

                        await this.getData(this.currentPage, this.perPage)

                        this.$swal({
                            icon: 'success',
                            title: status ? 'کاربر به حالت عادی بازگشت' : 'کاربر بازارگردان شد',
                            confirmButtonText: 'تایید',
                            // text: 'Your file has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    }
                })
            },

            sort(e) {
                console.log(e)

                let sort = this.$toSnakeCase(e.sortBy)
                // let sort = e.sortBy
                let sorting = e.sortDesc ? 'DESC' : 'ASC'

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        orderBy: sort,
                        sorting: sorting
                    }
                })

                this.getData(1, this.perPage)

            },

            async getData(page, perPage) {
                this.state.loading = true

                let queryParams = {
                    size: perPage,
                    page: page,
                    ...this.$route.query,
                }

                if (this.$route.name === 'show-accounts') {
                    queryParams = {
                        ...queryParams,
                        inviterId: Number(this.$route.params.id)
                    }
                }

                const res = await this.$axios(
                    '/users',
                    {
                        params: queryParams
                    }
                )

                this.state.loading = false
                this.items = res.data.content
                this.currentPage = res.data.number + 1
                this.rows = res.data.totalElements
            },

            async downloadExcel() {
                await this.$axios(
                    {
                        url: '/users/customers-excel',
                        method: 'GET',
                        responseType: 'blob', // important
                        params: {
                            fileName: this.$jmoment().format(this.$dateFormat['faDateTime']),
                            page: this.currentPage,
                            size: this.perPage,
                            ...this.$route.query
                        }
                    }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.$jmoment().format(this.$dateFormat['faDateTime']) + '.xls'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            }

        },
        mounted() {
            this.getData(1, this.perPage)
        },

    }
</script>
<style lang="scss">
    [v-cloak] {
        opacity: 0;
    }

    .nowrap {
        white-space: nowrap;
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }
</style>
